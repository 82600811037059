<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>CREAR OFERTA DE TRABAJO</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Nombre de la compañia</label>
          <input type="text" class="form-control" v-model="job.name">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Descripcion del trabajo</label>
          <textarea type="text" class="form-control" v-model="job.description" rows="5"></textarea>
          <label>File</label>
          <input type="file" class="form-control" @change="onChange">
          <button :disabled="disableBtn" class="btn bg-gradient-success float-end w-100 mt-2" @click="createJob">CREAR NUEVA OFERTA LABORAL</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {post} from "@/utils/httpRequest";
import UploadFile from "@/utils/uploadFile";
import Swal from "sweetalert2";

export default {
  data: () => ({
    disableBtn: false,
    tempFileRelated: null,
    job: {
      name: '',
      location: 'NA',
      salary: 'NA',
      description: ''
    }
  }),
  methods: {
    async onChange(e){
      this.tempFileRelated = e.target.files[0];
    },
    async createJob(){
      if(this.job.name !== '' && this.job.location !== '' && this.job.salary !== '' && this.job.description !== '' && this.tempFileRelated !== null){
        const {data} = await UploadFile.upload("job-offers/add-file",{file: this.tempFileRelated})
        this.disableBtn = true
        await post("job-offers/create",{
          ...this.job,
          file_related: data
        })
        await Swal.fire('Informacion creada correctamente', '', 'success')
        window.location.reload()
      }
    }
  }
}
</script>
<style scoped>

</style>
